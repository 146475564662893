import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Paper,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Tooltip,
    IconButton,
    Box,
    LinearProgress,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import StyledLink from "../../common/StyledLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {
    userFinalScoreResponse,
    viewResult,
    toggleResultButton,
    currentStatus,
    audioPlay,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    checkAllLevelAgentAudio,
    saveVideoStatus,
} from "../../../redux/sims/actions";
import AgentScript from "./AgentScript.jsx";
import OtherScript from "./OtherScript.jsx";
import { useLocation } from "react-router-dom";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import CloseIcon from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DashboardPermissions from "../../../permissions/dashboard";
import { permissionCheck } from "../../../utils/permissionCheck";
import {
    fetchVidoeDetails,
    uploadStatus,
    uploadComplete,
    uploadFiles,
    audioMerge,
} from "../../../services/result.service";
import { useStyles } from "./TakeSimResult";
import ResultScreenAccuracies from './ResultScreenAccuracies.jsx';
import TypingAndSelection from './TypingAndSelection.jsx';
import moment from 'moment';

const TakeSimResult = ({
    elapsedTime,
    userFinalScoreResponse,
    toggleResultButton,
    viewResult,
    userResponse,
    userResult,
    setUserResult,
    caseValuesArray,
    user,
    resultScreenInputData,
    isPost,
    simId,
    currentAudioPlaying,
    currentStatus,
    audioPlaying,
    audioPlay,
    assessorNotes,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    assessor_notes,
    checkAllLevelAgentAudio,
    allAudioProcessed,
    videoRecordingEnabled,
    videoUploadStatus,
    videoBlob,
    saveVideoStatus,
    simProductType,
    config,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    let simLevelId = urlParams.get("levelId");
    const assignment_id = urlParams.get("assignment_id");
    const [notes, setNotes] = React.useState("");
    const agent_user_id = urlParams.get("user_id");
    const sim_id = urlParams.get("sim_id");
    const reviewer_come = urlParams.get("reviewer");
    const [noteStatus, setNoteStatus] = React.useState("view");
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = React.useState("");
    const [videoDetailsFetched, setVideoDetailsFetched] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [callTranscript, setCallTranscript] = React.useState(0);
    const [enableVideoBtn, setEnableVideoBtn] = React.useState(false);
    const [disablePolling, setDisablePolling] = React.useState(false);
    //const [userResult, setuserResult] = React.useState(userResult);
    const [uploadFlag, setUplodFlag] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const chunkSize = 300000 * 100; //its 3MB, increase the number measure in mb
    const [fileState, setFileState] = useState({
        fileSize: 0,
        fileId: "",
        totalChunks: 0,
        totalChunksUploaded: 0,
        startChunk: 0,
        endChunk: chunkSize,
        fileToUpload: null,
        uploadedBytes: 0,
    });
    const[sendingToSnowfly, setSendingToSnowfly] = React.useState(false);
    const [sentToSnowfly, setSentToSnowfly] = React.useState(false);
    const [snowflyId, setSnowflyId] = useState(userResult?.snowfly_id ?? null);
    const [currentAudio, setCurrentAudio] = React.useState({});
    const [timePlay, setTimePlay] = React.useState(0);
    const [callOrAgentPlay, setCallOrAgentPlay] = React.useState(audioPlaying);
    const [lastFive, setLastFive] = React.useState("");

    useEffect(() => {
        if (userResult && userResult.scripts && userResult.scripts.length) {
            setUserResult(userResult);
            setSnowflyId(userResult?.snowfly_id ?? null);
        }
        if (process.env.REACT_APP_STAGE !== 'prod') {
            console.log("userResult = %o", userResult);
        }
    }, [userResult, setUserResult, setSnowflyId]);

    useEffect( () => {
        if (userResult && userResult.scripts && userResult.scripts.length) {
            setUserResult(userResult);
        }
    }, [userResult, setUserResult, sentToSnowfly]);

    useEffect(() => {
        if (isPost === false) {
            const payload = {
                simLevelId,
                simId: sim_id ? sim_id : simId,
                ...(assignment_id ? { assignment_id } : {}),
            };
            viewResult(payload, agent_user_id);
        } else {
            let lapsedTime = elapsedTime;
            let timeInSeconds = elapsedTime;
            if (userResponse.resumeLevel && elapsedTime && userResponse.elapsedTime) {
                let floatVal;
                if (!isNaN(lapsedTime) && !isNaN(userResponse.elapsedTime)) {
                    floatVal = lapsedTime + userResponse.elapsedTime;
                } else {
                    floatVal = (parseFloat(((''+lapsedTime)
                            .replace(/\s/g, ''))
                            .replace('m', ".")
                        ) + parseFloat(((''+userResponse.elapsedTime).replace(/\s/g, ''))
                            .replace('m', "."))
                    );
                }
                const totalSeconds = parseInt(floatVal) * 60 + parseInt((floatVal % 1).toFixed(2).substring(2))
                // const formattedTime = moment.utc(totalSeconds * 1000).format("HH:mm:ss");
                // elapsedTme = `${moment(formattedTime,"HH:mm:ss").minutes()}m ${moment(formattedTime,"HH:mm:ss").seconds()}s`
                timeInSeconds = totalSeconds;
                lapsedTime = totalSeconds;
            }
            const payload = {
                ...caseValuesArray,
                isPost: true,
                assignment_id: userResponse.assignment_id,
                elapsedTime: lapsedTime,
                totalSeconds: timeInSeconds,
            };
            if (elapsedTime) {
                userFinalScoreResponse(userResponse._id, payload);
            }

        }
        return () => {
            currentStatus(0);
            audioPlay("");
        };
    }, [
        userResponse,
        userFinalScoreResponse,
        caseValuesArray,
        viewResult,
        isPost,
        simId,
        simLevelId,
        assignment_id,
        sim_id,
        agent_user_id,
        elapsedTime,
        currentStatus,
        audioPlay,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        toggleResultButton(true);
        return () => toggleResultButton(false);
    });

    useEffect(() => {
        if (isPost !== true) {
            if (
                !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) &&
                !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                ) &&
                permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type
                )
            ) {
                if (userResult._id) {
                    getAssessorNotes(userResult._id);
                }
                setNotes(assessor_notes);
                setNoteStatus("view");
            } else if (
                permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) ||
                permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                )
            ) {
                if (userResult._id) {
                    getAssessorNotes(userResult._id);
                }
                setNotes(assessor_notes);
                setNoteStatus(assessor_notes === "" ? "add" : "edit");
            }
        } else {
            if (
                !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) &&
                !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                ) &&
                permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type
                )
            ) {
                setNoteStatus("view");
            } else if (
                permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) ||
                permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                )
            ) {
                setNoteStatus("add");
            }
        }
    }, [getAssessorNotes, userResult._id, assessor_notes, isPost]);

    useEffect(() => {
        const simID = sim_id ? sim_id : simId;
        checkAllLevelAgentAudio(simID, assignment_id, agent_user_id);
    }, [sim_id, simId, checkAllLevelAgentAudio, agent_user_id, assignment_id]);

    const handleClose = () => {
        addAssessor(false);
        setNotes(assessor_notes);
    };

    const handleClosePreview = () => {
        setOpen(false);
    };

    const openPreview = () => {
        if (
            !isPost &&
            userResult.video_status === "saved" &&
            userResult.video_url
        ) {
            setVideo(userResult.video_url);
        }
        setOpen(true);
    };

    const handleAdd = () => {
        const payload = {
            assessor_notes: notes,
        };
        SaveAssessorNotes(userResult._id, payload);
        addAssessor(false);
    };

    const handleEdit = () => {
        const payload = {
            assessor_notes: notes,
        };
        SaveAssessorNotes(userResult._id, payload, true);
        addAssessor(false);
    };

    const getVideoDetails = async () => {
        const videoDetails = await fetchVidoeDetails(userResponse._id);
        if (videoDetails.data.video_status === "saved") {
            setVideoDetailsFetched(true);
            setEnableVideoBtn(true);
            setVideo(videoDetails.data.video_url);
        }
        if (videoDetails.data.video_status === "processing") {
            setTimeout(async () => {
                getVideoDetails();
            }, 10000);
        }
    };

    const uploadChunk = useCallback((chunk) => {
        const {
            fileId,
            startChunk,
            endChunk,
            fileSize,
            totalChunksUploaded,
            uploadedBytes,
        } = fileState;
        uploadFiles(chunk, fileId, startChunk, endChunk, fileSize).then(({ data }) => {
            const endingChunk = Math.min(endChunk + chunkSize, fileSize);
            setFileState({
                ...fileState,
                totalChunksUploaded: totalChunksUploaded + 1,
                startChunk: endChunk,
                endChunk: endingChunk === fileSize ? endingChunk + 1 : endingChunk,
                uploadedBytes: endingChunk,
            });
            const prog = fileSize ? (uploadedBytes / fileSize) * 100 : 0.1;
            setProgress(prog);
        })
            .catch((err) => {
                console.error("Status call failed ", err);
                setOpenAlert(true);
            });
    }, [fileState, chunkSize]);

    const resetState = useCallback(() => {
        setFileState({
            fileSize: 0,
            fileId: "",
            totalChunks: 0,
            totalChunksUploaded: 0,
            startChunk: 0,
            endChunk: chunkSize,
            fileToUpload: null,
            uploadedBytes: 0,
        });
    }, [chunkSize]);

    const fileUpload = useCallback((totalChunksUploaded) => {
        const {
            totalChunks,
            fileToUpload,
            startChunk,
            endChunk,
            fileId,
        } = fileState;
        if (totalChunksUploaded <= totalChunks) {
            let chunk = fileToUpload.slice(startChunk, endChunk);
            uploadChunk(chunk);
        } else {
            uploadComplete(fileId, userResult._id).then(({data}) => {
                saveVideoStatus(data);
                resetState()
            });
        }
    }, [fileState, resetState, uploadChunk, userResult._id, saveVideoStatus]);

    useEffect(() => {
        if (fileState.fileSize > 0) {
            fileUpload(fileState.totalChunksUploaded);
        }
    }, [fileState.fileSize, fileState.totalChunksUploaded, fileUpload]);

    const LinearProgressWithLabel = (props) => {
        return (
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        );
    };

    const showVideoPreview = () => {
        if (isPost && videoRecordingEnabled) {
            return (
                <Tooltip
                    title={
                        videoDetailsFetched && videoUploadStatus !== null
                            ? ""
                            : progress < 100 ? 'Uploading Screen Recording. Please wait for few minutes.'
                                : "Sucessfulling uploded! Video is now processing. Please wait for few minutes."
                    }
                    placement="bottom"
                >
          <span className={classes.editSpan}>
            <Button
                variant="contained"
                className={classes.preview}
                size="small"
                onClick={openPreview}
                disabled={!videoDetailsFetched && !enableVideoBtn}
                startIcon={
                    videoDetailsFetched && videoUploadStatus !== null ? (
                        <VideoLabelIcon />
                    ) : (
                        <CircularProgress className={classes.previewLoader} />
                    )
                }
            >
              Preview Screen Recording
            </Button>
              {showProgress && !videoDetailsFetched && !enableVideoBtn ?
                  (<LinearProgressWithLabel className={classes.linearLoader} value={progress} />)
                  : null}
          </span>
                </Tooltip>
            );
        } else if (
            !isPost &&
            userResult.video_status === "saved" &&
            userResult.video_url
        ) {
            return (
                <Button
                    variant="contained"
                    className={classes.preview}
                    size="small"
                    onClick={openPreview}
                    startIcon={<VideoLabelIcon />}
                >
                    Preview Screen Recording
                </Button>
            );
        }

        return null;
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    const setTimePlayfun = (audioPlayer, data, type) => {
        setCallOrAgentPlay(audioPlaying ? audioPlaying : '');
        if (type === 'agent' && data.agentId) {
            userResult.agent_scripts.map((script) => {
                script.play = (script.agentId !== data.agentId);
                /*
                if (script.agentId === data.agentId) {
                    script.play = false;
                } else {
                    script.play = true;
                }*/
                return userResult;
            });
            userResult.scripts.map((script) => {
                script.play = true;
                return true;
            });
        } else {
            userResult.scripts.map((script) => {
                script.play = (script._id === data._id);
                /*
                if (script._id === data._id) {
                    script.play = false;
                } else {
                    script.play = true;
                }*/
                return userResult;
            });
            userResult.agent_scripts.map((script) => {
                script.play = true;
                return true;
            });
        }
        setUserResult(userResult)
        setCurrentAudio(audioPlayer);
        setTimePlay((prev) => prev + 1)
    };

    const pauseFunction = (data, type) => {
        if (type === 'agent' && data.agentId) {
            userResult.agent_scripts.map((script) => {
                if (script.agentId === data.agentId) {
                    script.play = true;
                    setTimePlay(0)
                }
                return userResult;
            });
        } else {
            userResult.scripts.map((script) => {
                if (script._id === data._id) {
                    script.play = true;
                    setTimePlay(0)
                }
                return userResult;
            });
        }
        setUserResult(userResult);
    };

    const sendAudioToSnowfly = async () => {
        const canSendToSnowfly = config.snowFlyAudioMerge &&
            (
                (userResult.level && userResult.level.send_to_snowfly) ||
                (userResult.level && userResult.level.send_to_snowfly) ||
                (userResult.send_to_snowfly)
            );

        if(canSendToSnowfly) {
            setSendingToSnowfly(true);
            try {
                const sendData = {
                    simName: userResult?.simTitle ?? userResult.simTitle,
                    levelName: userResult?.level?.name ?? userResult?.level.name,
                    levelNum: (userResult?.level?.order ?? userResult?.level?.order ?? 0)+1,
                    simId: simId,
                    responseId: userResult?._id ?? userResult?._id,
                    simLevelId: simLevelId,
                    user: user,
                }

                let result = await audioMerge(sendData);
                if (result && result.data && result.data.success) {
                    let userRes = {...userResult};
                    userRes.snowfly_id = result.data.snowflyId;
                    setUserResult(userRes);
                    setSnowflyId(userRes.snowfly_id);
                    setSentToSnowfly(true);
                }
                if (process.env.REACT_APP_STAGE !== 'prod') {
                    console.log('Sent recording of this level to Snowfly = %o', sendData);
                }
            } catch (err) {
                setSentToSnowfly(false);
                // /console.log('Failed Sending recording of this level to Snowfly = %o, %o', sendData, err);
            }
            setSendingToSnowfly(false);
        }
    };

    if (isPost && videoRecordingEnabled) {
        if (videoUploadStatus === true && !disablePolling) {
            setTimeout(async () => {
                getVideoDetails();
            }, 15000);
            setDisablePolling(true);
        } else if (videoUploadStatus === false && !disablePolling) {
            setOpenAlert(true);
            setDisablePolling(true);
        }
    }

    if (videoBlob && videoBlob.constructor !== Object && !uploadFlag && userResult._id) {
        setUplodFlag(true);
        setShowProgress(true);
        setProgress(0);
        resetState();
        const file_obj = videoBlob;
        const fileId = `${file_obj.size}-${file_obj.lastModified}-${file_obj.name}-${userResult._id}`;

        uploadStatus(fileId, file_obj.size).then(({ data }) => {
            const uploadedBytes = data.uploaded;
            const bytesRemaining = file_obj.size - uploadedBytes;
            const endingChunk = Math.min(uploadedBytes + chunkSize, file_obj.size);
            setFileState({
                fileSize: file_obj.size,
                fileId,
                totalChunks: Math.ceil(bytesRemaining / chunkSize),
                totalChunksUploaded: 0,
                startChunk: uploadedBytes,
                endChunk:
                    endingChunk === fileState.fileSize ? endingChunk + 1 : endingChunk,
                fileToUpload: file_obj,
                uploadedBytes,
            });
        }).catch((err) => {
            console.error("Status call failed ", err);
            setOpenAlert(true);
        });
    }

    return (
        <>
            {openAlert ? (
                <Alert
                    variant="filled"
                    className={classes.alertBox}
                    onClose={handleAlertClose}
                    severity="error"
                >
                    Error in saving the screen recording!
                </Alert>
            ) : null}
            <Grid container style={{ marginBottom: "10px", flexDirection: "row", alignContent: "space-between", flexShrink: "1" }} className={classes.flex}>
                <Grid item xs={5}>
                    {!sim_id && (
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            className={classes.mainBreadBlock}
                        >
                            <StyledLink to="/dashboard" className={classes.breadcrumbs}>
                                Dashboard
                            </StyledLink>
                        </Breadcrumbs>
                    )}
                </Grid>

                <Grid item xs={3}>
                    {/*Probably not the right place to put this, for starters here we go*/}
                    {/*(
              config.snowFlyAudioMerge &&
              userResult &&
              ((userResult.level && userResult.level.send_to_snowfly) || userResult.send_to_snowfly) &&
              (!userResult.snowfly_id)
          ) && (
          <Tooltip
              title='Send the current SIM level audio to Snowfly'
              placement="bottom"
          >
            <span>
              <Button
                  variant="contained"
                  onClick={sendAudioToSnowfly}
                  disabled={sendingToSnowfly}
                  style={{ display: (userResult?.level?.snowfly_id || userResult?.snowfly_id) ? 'none' : 'default' }}
              >
                {sendingToSnowfly && (<CircularProgress color="primary"/>)}
                Send Recording Session to Snowfly
              </Button>
            </span>
          </Tooltip>
          )*/}
                </Grid>

                <Grid item xs={4}>
                    {reviewer_come === "false" ? (
                        <StyledLink
                            color="inherit"
                            underline="always"
                            to={{
                                pathname: "/sims",
                                state:
                                    location.state.assignment && location.state.assignment.sim_id
                                        ? {
                                            ...location.state.assignment,
                                            reviewer: false,
                                            type: location.state.type,
                                        }
                                        : {
                                            sim_id: sim_id || simId,
                                            reviewer: false,
                                            type: location.state.type,
                                        },
                            }}
                        >
                            <Tooltip
                                title='Re-Take or go to next level'
                                placement="bottom"
                            >
                  <span>
                    <Button
                        variant="contained"
                        className={classes.retake}
                        disableElevation
                        color="primary"
                        size="small"
                    >
                      Re-take
                    </Button>
                  </span>
                            </Tooltip>
                        </StyledLink>
                    ) : (
                        <StyledLink
                            color="inherit"
                            underline="always"
                            to={{
                                pathname: "/sims",
                                state:
                                    location.state.assignment && location.state.assignment.sim_id
                                        ? { ...location.state.assignment, reviewer: true }
                                        : {
                                            sim_id: sim_id || simId,
                                            reviewer: true,
                                            assignment_id,
                                            agent_user_id,
                                        },
                            }}
                        >
                            <Button
                                variant="contained"
                                className={classes.retake}
                                disableElevation
                                color="primary"
                                size="small"
                            >
                                View Other Level Result
                            </Button>
                        </StyledLink>
                    )
                    }

                    {showVideoPreview()}
                </Grid>
            </Grid>
            <Dialog onClose={handleClosePreview} open={open} fullWidth maxWidth="md">
                <DialogTitle className={classes.previewDialogTitle}>
                    Screen Recording
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClosePreview}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.previewDialog}>
                        <video
                            className={classes.videoModal}
                            width="750"
                            height="500"
                            controls
                            autoPlay
                        >
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={handleClose}
                open={assessorNotes}
                classes={{ paper: classes.dialog }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Assessor Notes</DialogTitle>
                <DialogContent>
                    <div style={{ padding: "0.5rem", width: "27vw" }}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                style: { fontSize: 14, paddingLeft: 8 },
                            }}
                            fullWidth
                            multiline
                            minRows={5}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className={classes.textArea}
                            placeholder="Type your note here"
                            disabled={
                                !permissionCheck(
                                    DashboardPermissions.assessor_notes.update.task,
                                    DashboardPermissions.assessor_notes.update.type
                                ) &&
                                !permissionCheck(
                                    DashboardPermissions.assessor_notes.create.task,
                                    DashboardPermissions.assessor_notes.create.type
                                ) &&
                                permissionCheck(
                                    DashboardPermissions.assessor_notes.view.task,
                                    DashboardPermissions.assessor_notes.view.type
                                )
                            }
                        />
                        <Button
                            size="small"
                            style={{
                                fontWeight: 600,
                                padding: "3px 11px",
                                backgroundColor: "#F8DEE7",
                                float: "right",
                                color: "#A55B73",
                                margin: "8px 0px",
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        {noteStatus === "edit" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.assessor_notes.update.task,
                                        DashboardPermissions.assessor_notes.update.type
                                    )
                                        ? "You don't have permission to update"
                                        : ""
                                }
                                placement="top"
                            >
                <span className={classes.editSpan}>
                  <Button
                      size="small"
                      className={classes.button}
                      disabled={
                          !permissionCheck(
                              DashboardPermissions.assessor_notes.update.task,
                              DashboardPermissions.assessor_notes.update.type
                          )
                      }
                      onClick={handleEdit}
                  >
                    Save
                  </Button>
                </span>
                            </Tooltip>
                        ) : noteStatus === "add" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.assessor_notes.create.task,
                                        DashboardPermissions.assessor_notes.create.type
                                    )
                                        ? "You don't have permission to create"
                                        : ""
                                }
                                placement="top"
                            >
                <span className={classes.editSpan}>
                  <Button
                      size="small"
                      className={classes.button}
                      disabled={
                          !permissionCheck(
                              DashboardPermissions.assessor_notes.create.task,
                              DashboardPermissions.assessor_notes.create.type
                          )
                      }
                      onClick={handleAdd}
                  >
                    Save
                  </Button>
                </span>
                            </Tooltip>
                        ) : null}
                    </div>
                </DialogContent>
            </Dialog>
            {Object.keys(userResult).length > 0 ?
                (<Grid container spacing={4}>
                    <ResultScreenAccuracies
                        userResult={userResult}
                        setUserResult={setUserResult}
                        snowflyId={snowflyId}
                        setSnowflyId={setSnowflyId}
                        isPost={isPost}
                        config={config}
                        sendAudioToSnowfly={sendAudioToSnowfly}
                        sendingToSnowfly={sendingToSnowfly}
                    />
                    <Grid container className={classes.girdContainer} item md={simProductType.includes('Voice Only') ? 9 : 5}>
                        <Paper className={`${classes.root}`}>
                            <h2>Call Transcript</h2>
                            {userResult?.scripts &&
                                userResult?.scripts.map((singleScript, index) => {
                                    if (callOrAgentPlay && !audioPlaying) {
                                        singleScript.play = undefined;
                                        setCallOrAgentPlay(audioPlaying);
                                        setTimePlay(0);
                                    }
                                    if (singleScript.play === undefined) {
                                        singleScript.play = true;
                                    }
                                    if (singleScript.sim_character_id.type === "agent") {
                                        const currentAgentScript = userResult.agent_scripts.filter(
                                            (ele) =>
                                                ele.sim_script_id.toString() ===
                                                singleScript._id.toString()
                                        );
                                        // eslint-disable-next-line
                                        currentAgentScript.map((agent, index) => {
                                            agent.id = index;
                                            if (agent.play === undefined) {
                                                agent.play = true;
                                            } else if (callOrAgentPlay && !audioPlaying) {
                                                agent.play = true;
                                                setCallOrAgentPlay(audioPlaying);
                                                setTimePlay(0);
                                            }
                                        });
                                        return (
                                            <Paper className={classes.agentList} key={index}>
                                                <AgentScript
                                                    key={index}
                                                    index={index}
                                                    simCharacter={singleScript.sim_character_id.type}
                                                    currentAudioPlaying={currentAudioPlaying}
                                                    totalLength={userResult.scripts.length - 1}
                                                    // status={status}
                                                    setLastFive={setLastFive}
                                                    currentStatus={currentStatus}
                                                    audioPlaying={audioPlaying}
                                                    singleScript={singleScript}
                                                    currentAgentScript={currentAgentScript}
                                                    latestAgentScript={currentAgentScript[0]}
                                                    callTranscript={callTranscript}
                                                    setCallTranscript={setCallTranscript}
                                                    setTimePlayfun={setTimePlayfun}
                                                    currentAudio={currentAudio}
                                                    timePlay={timePlay}
                                                    pauseFunction={pauseFunction}
                                                />
                                                <div key={singleScript._id}>
                                                    <Accordion className={classes.accordian}>
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon className={classes.expand} />
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <p className={classes.heading}>
                                                                Last 5 agent audios
                                                            </p>
                                                        </AccordionSummary>
                                                        {currentAgentScript.length > 0 &&
                                                            currentAgentScript
                                                                .slice(1)
                                                                .map((latestAgentScript, i) => {
                                                                    if (
                                                                        lastFive &&
                                                                        lastFive.audio_url &&
                                                                        lastFive.audio_url ===
                                                                        latestAgentScript.audio_url
                                                                    ) {
                                                                        return (
                                                                            <AccordionDetails key={i}>
                                                                                <AgentScript
                                                                                    key={index}
                                                                                    index={index}
                                                                                    simCharacter={
                                                                                        singleScript.sim_character_id.type
                                                                                    }
                                                                                    currentAudioPlaying={
                                                                                        currentAudioPlaying
                                                                                    }
                                                                                    totalLength={
                                                                                        userResult.scripts.length - 1
                                                                                    }
                                                                                    // status={status}
                                                                                    currentStatus={currentStatus}
                                                                                    audioPlaying={audioPlaying}
                                                                                    singleScript={singleScript}
                                                                                    value={true}
                                                                                    currentAgentScript={currentAgentScript}
                                                                                    latestAgentScript={latestAgentScript}
                                                                                    setLastFive={setLastFive}
                                                                                    setTimePlayfun={setTimePlayfun}
                                                                                    currentAudio={currentAudio}
                                                                                    timePlay={timePlay}
                                                                                    pauseFunction={pauseFunction}
                                                                                />
                                                                            </AccordionDetails>
                                                                        );
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <AccordionDetails key={i}>
                                                                                <AgentScript
                                                                                    key={index}
                                                                                    index={index}
                                                                                    currentAudioPlaying={
                                                                                        currentAudioPlaying
                                                                                    }
                                                                                    totalLength={
                                                                                        userResult.scripts.length - 1
                                                                                    }
                                                                                    // status={status}
                                                                                    currentStatus={currentStatus}
                                                                                    audioPlaying={audioPlaying}
                                                                                    singleScript={singleScript}
                                                                                    currentAgentScript={currentAgentScript}
                                                                                    latestAgentScript={latestAgentScript}
                                                                                    onLoadedData={
                                                                                        lastFive.audio_url ===
                                                                                        latestAgentScript.audio_url
                                                                                    }
                                                                                    setTimePlayfun={setTimePlayfun}
                                                                                    currentAudio={currentAudio}
                                                                                    timePlay={timePlay}
                                                                                    pauseFunction={pauseFunction}
                                                                                />
                                                                            </AccordionDetails>
                                                                        );
                                                                    }
                                                                })}
                                                    </Accordion>
                                                </div>
                                            </Paper>
                                        );
                                    } else {

                                        return (
                                            <OtherScript
                                                index={index}
                                                currentAudioPlaying={currentAudioPlaying}
                                                totalLength={userResult?.scripts.length - 1 ?? 0}
                                                audioPlaying={audioPlaying}
                                                key={singleScript._id}
                                                singleScript={singleScript}
                                                callTranscript={callTranscript}
                                                setCallTranscript={setCallTranscript}
                                                setTimePlayfun={setTimePlayfun}
                                                currentAudio={currentAudio}
                                                timePlay={timePlay}
                                                pauseFunction={pauseFunction}
                                            />
                                        );
                                    }
                                })}
                        </Paper>
                    </Grid>
                    {!simProductType.includes('Voice Only') && <TypingAndSelection />}
                </Grid>) :
                <div style={{ textAlign: 'center', padding: '55px' }}>
                    <CircularProgress />
                </div>}
        </>
    );
};

TakeSimResult.propTypes = {
    elapsedTime: PropTypes.number,//string,
    totalSeconds: PropTypes.number,
    userFinalScoreResponse: PropTypes.func,
    toggleResultButton: PropTypes.func,
    viewResult: PropTypes.func,
    userResponse: PropTypes.object,
    userResult: PropTypes.object,
    user: PropTypes.object,
    caseValuesArray: PropTypes.object,
    isPost: PropTypes.bool,
    currentAudioPlaying: PropTypes.any,
    currentStatus: PropTypes.func,
    audioPlaying: PropTypes.string,
    audioPlay: PropTypes.func,
    assessorNotes: PropTypes.bool,
    addAssessor: PropTypes.func,
    SaveAssessorNotes: PropTypes.func,
    getAssessorNotes: PropTypes.func,
    assessor_notes: PropTypes.string,
    checkAllLevelAgentAudio: PropTypes.func,
    allAudioProcessed: PropTypes.bool,
    videoRecordingEnabled: PropTypes.bool,
    videoUploadStatus: PropTypes.bool,
    videoBlob: PropTypes.object,
    saveVideoStatus: PropTypes.func,
    simProductType: PropTypes.string
};

const mapStateToProps = (state) => ({
    elapsedTime: state.sim.elapsedTime,
    totalSeconds: state.sim.totalSeconds,
    userResponse: state.sim.userResponse,
    userResult: state.sim.userResult,
    user: state.login.user,
    caseValuesArray: state.sim.caseValuesArray,
    resultScreenInputData: state.sim.resultScreenInputData,
    simId: state?.sim?.stats?.result?.sim_id,
    assessorNotes: state.sim.assessorNotes,
    currentAudioPlaying: state.sim.currentAudioPlaying,
    audioPlaying: state.sim.audioPlaying,
    assessor_notes: state.sim.assessor_notes,
    allAudioProcessed: state.sim.allAudioProcessed,
    videoRecordingEnabled: state.sim.videoRecordingEnabled,
    videoUploadStatus: state.sim.videoUploadStatus,
    videoBlob: state.sim.videoBlob,
    simProductType: state.sim.currentSimProductType,
});

const mapDispatchToProps = {
    userFinalScoreResponse: userFinalScoreResponse,
    viewResult,
    toggleResultButton,
    currentStatus,
    audioPlay,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    checkAllLevelAgentAudio,
    saveVideoStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeSimResult);
